import axios from 'axios'
import md5 from 'js-md5'
import moment from 'moment'
import qs from 'qs'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
  // baseURL: 'http://192.168.22.2:8084',
  // baseURL: 'http://192.168.1.110:8084',
  // baseURL: window.baseUrl,
  baseURL: 'http://222.240.227.208:10180',
  timeout: 10000,
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (config.params) {
      for (let key in config.params) {
        if (
          !config.params[key] ||
          config.params[key] == null ||
          config.params[key] == undefined
        ) {
          delete config.params[key]
        }
      }
    }
    if (config.method == 'get') {
      if (!config.params) {
        config.params = {}
      }
      config.params.channelid = '1003'
      config.params.timestamp = moment().format('YYYYMMDDHHmmss')
      let params = createSignAndSort(config.params)
      config.params = params
    }
    if (config.method == 'post') {
      if (!config.data) {
        config.data = {}
      }
      config.data.channelid = '1003'
      config.data.timestamp = moment().format('YYYYMMDDHHmmss')
      let sign = createPostSignAndSort(config.data)
      config.data.sign = sign
      if (config.data.formData == 1) {
        config.data = qs.stringify(config.data)
      }
    }
    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    return res.data
  },
  (error) => {
    return Promise.reject(error)
  }
)

const SECRET_KEY = 'DJKSBNW123'

function createSignAndSort(params) {
  let data = {}
  let str = ''
  Object.keys(params)
    .sort()
    .map((key) => {
      data[key] = params[key] + ''
      str += `${key.toUpperCase()}=${data[key].toUpperCase()}&`
    })
  let md5Str = str + SECRET_KEY
  data.sign = md5(md5Str)
  return data
}

function createPostSignAndSort(params) {
  let data = {}
  let str = ''
  Object.keys(params)
    .sort()
    .map((key) => {
      data[key] = params[key] + ''
      str += `${key.toUpperCase()}=${data[key].toUpperCase()}&`
    })
  let md5Str = str + SECRET_KEY
  data.sign = md5(md5Str)
  return data.sign
}

export default service
