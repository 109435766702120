import Vue from 'vue'
import App from './App.vue'

import router from "./router"
import moment from "moment";
import axios from "./request";
import * as echarts from "echarts"

// import VConsole from "vconsole";
// const vs = new VConsole()
// Vue.use(vs)

import WotDesign from 'wot-design'
import 'wot-design/lib/theme-default/index.css'
Vue.use(WotDesign)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment
Vue.prototype.$goBack = () => {
  router.go(-1)
}

Vue.prototype.$goHome = () => {
  router.push('/home')
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
